import React, { FC } from "react";
import { useParams } from "react-router-dom";
import ChatBox from "../../components/Chat";

const Home: FC = () => {
  let { id } = useParams();
  return (
    <div className="fullscreen-container bg-[#090C29] ">
      <ChatBox studyId={id} />
    </div>
  );
};

export default Home;
