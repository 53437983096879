import Widget from "./src/index";
import {
  addResponseMessage,
  addUserMessage,
  setMsgLoaderVisible,
  deleteMessages,
  dropMessages,
} from "./src/store/dispatcher";
import React, { useState, useEffect, useRef } from "react";
import OpenAI, { toFile } from "openai";

import { v4 as uuidv4 } from "uuid";

import "./chat.css";
import MicIcon from "./icons/mic";
import TextMode from "./icons/text";

import { square, waveform } from "ldrs";
square.register();
waveform.register();

const ChatSideBar = (opts: { studyId: string }) => {
  const [chatEnabled, setChatEnabled] = useState(false);
  const [searchInputField, setSearchInputField] = useState("");

  const customLauncher = (launchChatMethod: any) => {
    setChatEnabled((prv) => {
      if (!prv) launchChatMethod();
      return true;
    });
  };
  const handleSearchInputChange = (e) => {
    setSearchInputField(e.target.value);
  };
  const handleNewUserMessage = (newMessage: any) => {
    sendMessage(newMessage.trim());
  };
  const { studyId } = opts;

  let title = "";

  /// DAN H'S DANGER ZONE

  let REACT_APP_OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY;
  let REACT_APP_HOPPR_CONFIG = process.env.REACT_APP_HOPPR_CONFIG;
  let REACT_APP_HOPPR_API_KEY = process.env.REACT_APP_HOPPR_API_KEY;

  const openai = new OpenAI({
    apiKey: REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [textBuffer, setTextBuffer] = useState("");
  const [visibleText, setVisibleText] = useState("");
  const firstSentence = useRef(null);
  const firstSentencePlayed = useRef(false);
  const [eomReceived, setEomReceived] = useState(true);
  const [sentences, setSentences] = useState([]);
  const [words, setWords] = useState([]);
  const [isSentenceComplete, setIsSentenceComplete] = useState([true, true]);
  const [messageId, setMessageId] = useState(null);
  const [config, setConfig] = useState(null);
  const [connected, setConnected] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const openAiConversation = useRef([]);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    const loadConfig = async () => {
      console.log("Loading config");
      const configResponse = await fetch(REACT_APP_HOPPR_CONFIG);
      let jsonConfig = await configResponse.json();
      setConfig(jsonConfig);
    };
    loadConfig();
    dropMessages();
  }, []);

  const setup = async () => {
    mediaRecorderRef.current = null;
    setRecording(false);
    setTextBuffer("");
    setVisibleText("");
    firstSentence.current = null;
    firstSentencePlayed.current = false;
    setEomReceived(true);
    setMessageId(null);
    setIsSentenceComplete([true, true]);
    setSentences([]);
    setWords([]);
    setConnected(false);
    await setupOpenAI();
  };

  useEffect(() => {
    async function innerSetup() {
      await setup();
    }
    if (config == null) {
      return;
    }
    console.log("Config loaded. Starting chat experience.");
    innerSetup();
  }, [config]);
  // Observe sentences
  useEffect(() => {
    function playNext(sentence) {
      const text = sentence[0];
      const mp3 = sentence[1];
      if (shouldAnimate) {
        if (mp3 != null) {
          console.log("Starting audible sentence " + JSON.stringify(text));
          setIsSentenceComplete([false, false]);
          const audio = new Audio(mp3);
          audio.addEventListener(
            "ended",
            () => {
              console.log("Finished saying sentence " + JSON.stringify(text));
              setIsSentenceComplete((complete) => {
                if (!complete[1]) {
                  return [true, false];
                }
                if (complete[0]) {
                  return complete;
                }
                console.debug("Popping sentence in audio");
                setSentences((s) => s.filter((i) => i[0] !== text));
                return [true, true];
              });
            },
            { once: true }
          );
          audio.play();
        } else {
          console.log("Starting silent sentence " + JSON.stringify(text));
          setIsSentenceComplete([true, false]);
        }
        setWords((prev) => prev.concat(text.split(" ")));
      } else {
        console.log("Adding unanimated sentence.")
        setIsSentenceComplete([true, false]);
        setWords((prev) => prev.concat(text))
      }
    }
    console.debug("There are now " + sentences.length + " sentences");
    if (sentences.length === 0) {
      return;
    }
    setLoadingVisible(false);
    setIsSentenceComplete((complete) => {
      if (complete[0] && complete[1]) {
        playNext(sentences[0]);
      }
      return complete;
    });
  }, [sentences]);

  // Observe individual words
  useEffect(() => {
    function showNext(word) {
      console.debug("Adding word " + JSON.stringify(word));
      setVisibleText((prevMsg) => {
        const text = prevMsg + word + " ";
        setMessageId((id) => {
          if (id == null) {
            const newId = uuidv4().toString();
            addResponseMessage(text, newId);
            return newId;
          }
          deleteMessages(1, id);
          addResponseMessage(text, id);
          return id;
        });
        return text;
      });
      const timeout = word.endsWith(",") ? 600 : 220;
      setTimeout(() => {
        setWords((prev) => prev.slice(1));
      }, timeout);
    }
    console.debug("There are now " + words.length + " words");
    if (words.length === 0) {
      setIsSentenceComplete((complete) => {
        console.debug("Finished words for sentence");
        if (!complete[0]) {
          return [false, true];
        }
        if (complete[1]) {
          return complete;
        }
        console.debug("Popping sentence in words");
        setSentences((s) => s.slice(1));
        return [true, true];
      });
      return;
    }
    showNext(words[0].trim());
  }, [words]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    setRecording(true);
  };

  const stopRecording = () => {
    const recorder = mediaRecorderRef.current
    if (recorder != null) {
      recorder.stop();
    }
    setRecording(false);
  };

  const handleDataAvailable = async ({ data }) => {
    if (data.size <= 10000) {
      console.warn(
        "Ignoring audio input because " +
          data.size +
          " bytes is less than the min threshold."
      );
      return;
    }
    console.log("Getting audio transcription");
    const buffer = await data.arrayBuffer();
    const transcription = await openai.audio.transcriptions.create({
      file: await toFile(buffer, "speech.mp3"),
      model: "whisper-1",
    });
    sendMessage(transcription.text);
  };

  const setLoadingVisible = (visible) => {
    console.log("Changing loading animation visible " + visible);
    setMsgLoaderVisible(visible);
  };

  const sendMessage = async (text) => {
    setShouldAnimate(true)
    setIsHovered(false)
    addUserMessage(text);
    setEomReceived(false);
    setSearchInputField("");
    setLoadingVisible(true);
    setMessageId(null);
    setTextBuffer("");
    setVisibleText("");
    firstSentence.current = null;
    firstSentencePlayed.current = false;

    await sendMessageOpenAI(text);
  };

  const sendMessageOpenAI = async (text) => {
    openAiConversation.current.push({ role: "user", content: text });
    const completion = await openai.chat.completions.create({
      messages: openAiConversation.current,
      model: "gpt-4-1106-preview",
      stream: true,
    });
    let message = "";
    for await (const chunk of completion) {
      const choice = chunk.choices[0];
      const text = choice.delta.content;
      if (text != null) {
        await appendResponse(text);
        message += choice.delta.content;
      }
      if (choice.finish_reason != null) {
        await appendResponse("<|EOM|>");
      }
    }
    openAiConversation.current.push({ role: "assistant", content: message });
  };

  const queueResponse = async (response, speak = true) => {
    if (response.trim().length === 0) {
      maybePlayFirstSentence();
      return;
    }
    const speech = speak ? await createSpeechMp3(response) : null;
    if (
      speak &&
      !firstSentencePlayed.current &&
      firstSentence.current == null
    ) {
      console.log("Delaying first sentence: " + JSON.stringify(response));
      firstSentence.current = [response, speech];
      setTimeout(() => {
        maybePlayFirstSentence();
      }, 2000);
      return;
    }
    maybePlayFirstSentence();
    setSentences((prev) => [...prev, [response, speech]]);
  };

  const maybePlayFirstSentence = () => {
    const sentence = firstSentence.current;
    if (sentence != null) {
      console.log("Enqueuing delayed first sentence.");
      setSentences((prev) => [...prev, sentence]);
      firstSentence.current = null;
      firstSentencePlayed.current = true;
    }
  };

  const appendResponse = async (text) => {
    if (text == null) {
      return;
    }
    //@ts-ignore
    setTextBuffer(async (prevText) => {
      if (text === "<|EOM|>") {
        console.log("Received EOM.");
        await queueResponse(await prevText);
        setEomReceived(true);
        return "";
      }
      const newText = (await prevText) + text;
      const trimmed = text.trim();
      const lastChar = trimmed[trimmed.length - 1];
      if (lastChar === "?" || lastChar === "!") {
        await addSentence(newText);
        return "";
      } else if (lastChar === ".") {
        const tokens = newText.split(" ");
        const lastToken = tokens[tokens.length - 1];
        console.debug("Last token: " + lastToken + " in " + newText);
        //@ts-ignore
        if (
          lastToken.length <= 1 ||
          //@ts-ignore
          isNaN(lastToken.substring(0, lastToken.length - 1))
        ) {
          await addSentence(newText);
          return "";
        }
      }
      return newText;
    });
  };

  const addSentence = async (sentence) => {
    console.log("Adding sentence: " + JSON.stringify(sentence));
    await queueResponse(sentence);
  };

  const createSpeechMp3 = async (text) => {
    const mp3 = await openai.audio.speech.create({
      model: "tts-1",
      voice: "alloy",
      input: text,
    });
    const buffer = await mp3.arrayBuffer();
    return URL.createObjectURL(new Blob([buffer]));
  };

  const setupOpenAI = async () => {
    console.log("Setting up OpenAI");
    // TODO: Load report from HOPPR API using study id
    let report = "";
    try {
      console.log("Loading report from HOPPR.")
      const hopprHeaders = { "x-api-key": REACT_APP_HOPPR_API_KEY };
      const inferenceResponse = await fetch(
        `https://api.hoppr.ai/studies/${studyId}/inference`,
        {
          method: "POST",
          headers: hopprHeaders,
          body: JSON.stringify({ model: "rsna_demo", prompt: "Generate a full report for this study." })
        }
      );
      const inferenceJson = await inferenceResponse.json();
      const inferenceId = inferenceJson["id"]
      console.log(`Loading inferencee results ${inferenceId}`)
      const reportResponse = await fetch(
        `https://api.hoppr.ai/studies/${studyId}/inference/${inferenceId}`,
        {
          method: "GET",
          headers: hopprHeaders
        }
      );
      const reportResponseJson = await reportResponse.json();
      if (!reportResponseJson["success"]) {
        throw new Error("Inference request was unsuccessful.");
      }
      report = reportResponseJson["response"];
    } catch (e) {
      queueResponse(
        "Something seems to have gone wrong in my initialization. Please alert the HOPPR team so they can try to fix me.",
        false
      )
      console.error(e)
    }
    openAiConversation.current.push({
      role: "system",
      content:
        "You are a helpful radiology assistant named " +
        config["botName"] +
        " examining a radiology study. You do not know the user's name.",
    });
    openAiConversation.current.push({
      role: "system",
      content:
        "If asked, you were created by HOPPR as a demonstration of their foundation model's capabilities.",
    });
    openAiConversation.current.push({
      role: "system",
      content:
        "Keep all responses in plain text and do not generate any code, tables, etc. if asked.",
    });
    openAiConversation.current.push({
      role: "system",
      content:
        "Keep responses short but thorough. Speak to the user as if they have a background in medicine. Do not explain processes. Stay on the topic of the radiology studies. If the user prompts with anything not related, direct the conversation back to the study at hand, but don't repeat the study findings.",
    });
    openAiConversation.current.push({
      role: "system",
      content:
        "Do not ask for any information that has not been provided. Do not refer to the information as 'provided'. Act like it is something you already know. Also, refrain from saying 'radiology studies' too much.",
    });
    openAiConversation.current.push({
      role: "system",
      content:
        "A radiologist reviewing this study would generate a report similar to this: " +
        report +
        " Do not directly refer to this report but rather act like this is the result of you evaluating the current study.",
    });
    openAiConversation.current.push({
      role: "system",
      content: "This patient is a male.",
    });
    openAiConversation.current.push({
      role: "system",
      content: "This patient is 66 years old.",
    });
    setConnected(true);
    await queueResponse(
      config["welcomeMessage"]
        .replace("{botName}", config["botName"])
        .replace("{modality}", "CT"),
      false
    );
  };

  useEffect(() => {
    setIsDisabled(
      !connected ||
        !eomReceived ||
        sentences.length > 0 ||
        firstSentence.current != null
    );
  }, [connected, sentences, eomReceived, firstSentence]);

  const toggleIsHovered = (hovered) => {
    setIsHovered(hovered);
  }

  const stopAnimating = () => {
    console.log("Stopping response animation.")
    setShouldAnimate(false)
  }

  /// END OF DAN H'S DANGER ZONE

  return (
    <div className="chatSidebar">
      <Widget
        title={title}
        subtitle={""}
        handleNewUserMessage={handleNewUserMessage}
        emojis={false}
        launcher={customLauncher}
      />
      <div className="inputPanel">
        {!isDisabled && (
          <div className="sendPanel">
            <button
              className="arbitrary micButton"
              onMouseDown={startRecording}
              onMouseUp={stopRecording}
              onMouseLeave={stopRecording}
            >
              <MicIcon size={"30px"} color={recording ? "red" : "white"} />
            </button>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNewUserMessage(searchInputField);
              }}
            >
              <input
                className="textEntryComponent"
                onChange={handleSearchInputChange}
                value={searchInputField}
              />
            </form>
            <button
              className="arbitrary sendButton"
              disabled={searchInputField.trim().length === 0}
              onClick={() => {
                handleNewUserMessage(searchInputField);
              }}
            >
              <TextMode size={"30px"} />
            </button>
          </div>
        )}
        {isDisabled && (
          <div className="loadingContainer">
            <div onMouseEnter={() => toggleIsHovered(true)} onMouseLeave={() => toggleIsHovered(false)} onClick={stopAnimating}>
              {(isHovered || !shouldAnimate) && (
                <l-square size="32" speed="1.3" color="white" />
              )}
              {(!isHovered && shouldAnimate) && (
                <l-waveform size="32" speed="1.3" color="white" />
              )}
            </div>
          </div>
        )}
        {config && (
          <div
            className="disclaimer"
            dangerouslySetInnerHTML={{ __html: config["disclaimerText"] }}
          />
        )}
      </div>
    </div>
  );
};

export default ChatSideBar;
